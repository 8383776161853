<template>
    <div v-loading="$wait.is('loading.services_types')">
        <div class="flex justify-between border-b border-solid border-gray-200 pb-2 mb-5">
            <h1>{{ $t('statistics.services_types') }}</h1>
        </div>

        <apexchart type="pie" width="400" :options="chartOptions" :series="series" />
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';

export default {
    components: {
        apexchart: VueApexCharts,
    },

    props: {
        chartData: {
            type:    Object,
            default: () => {},
        },
    },

    data() {
        return {
            series:       this.chartData.data,
            chartOptions: {
                labels: this.chartData.labels,
                legend: {
                    formatter: (seriesName, opts) => [seriesName, ' - ', opts.w.globals.series[opts.seriesIndex]],
                },
            },
        };
    },
};
</script>
